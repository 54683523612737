import styled from '@emotion/styled'
import type { SetupIntentCreationResult } from '@orus.eu/backend/src/services/payment-method-service'
import {
  ContentContainerAppClient,
  NameAuthenticationDialog,
  PageTitle,
  PersistentNotification,
  useCrash,
  useTranslate,
} from '@orus.eu/pharaoh'
import type { Result } from '@orus.eu/result'
import { useLocation } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { trpc, trpcReact } from '../../client'
import { PaymentMethodUpdateForm } from '../organisms/payment-update-form'

export default function PaymentMethodUpdatePageWithToken(): JSX.Element {
  const translate = useTranslate()
  const search = useLocation().search
  const crash = useCrash()
  const token = search.token

  const [setupIntentData, setSetupIntentData] = useState<
    Result<SetupIntentCreationResult, 'token-invalid'> | undefined
  >(undefined)

  const { data: nameLengthData } = trpcReact.paymentMethod.getCustomerNameLengthWithoutConnection.useQuery({
    jwt: token || '',
  })
  const nameLength = nameLengthData?.type === 'success' ? nameLengthData.output : undefined

  const [nameFirstLettersUserInput, setNameFirstLettersUserInput] = useState('')

  const isNameAuthenticated = trpcReact.paymentMethod.checkCustomerNameWithoutConnection.useQuery({
    jwt: token || '',
    nameFirstLettersUserInput,
  }).data

  useEffect(() => {
    async function createSetupIntent(token: string) {
      const data = await trpc.paymentMethod.createSetupIntentWithoutConnection.mutate({ jwt: token })
      setSetupIntentData(data)
    }

    if (isNameAuthenticated) {
      if (!token) {
        crash({ type: 'unexpected-error', err: new Error('Token is missing') })
        return
      }
      createSetupIntent(token).catch(() => {})
    }
  }, [isNameAuthenticated, token, crash])

  if (setupIntentData?.type === 'failure') {
    return (
      <PersistentNotification variant="danger">
        Ce lien a expiré, nous vous suggérons de demander un nouveau lien à votre conseiller Orus !
      </PersistentNotification>
    )
  }

  const success = search.redirect_status
  if (success === 'succeeded') {
    return (
      <PersistentNotification variant="success">Votre moyen de paiement a bien été mis à jour !</PersistentNotification>
    )
  }

  if (!setupIntentData) {
    return <NameAuthenticationDialog nameLength={nameLength} tryAuthentication={setNameFirstLettersUserInput} />
  }

  return (
    <ContainerWithOverflowScroll>
      <PageTitle title={translate('subscription_funnel_checkout_payment_details')} />
      <ContentContainerAppClient>
        <PaymentMethodUpdateForm
          stripeClientSecret={setupIntentData.output.clientSecret}
          backButton={false}
          token={token ?? undefined}
        />
      </ContentContainerAppClient>
    </ContainerWithOverflowScroll>
  )
}

const ContainerWithOverflowScroll = styled.div`
  max-height: 100vh;
  overflow: scroll;
`
